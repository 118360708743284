import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import { NextSeo } from "next-seo";

const translationNamespaces = ["common", "nav"];

export const getStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, translationNamespaces)),
    },
  };
};

function TranslatedError() {
  const { t } = useTranslation();
  return (
    <div className="relative">
      <NextSeo
        title={t("common:page-not-found-title")}
        description={t("common:page-not-found")}
        noindex
        nofollow
      />
      <div className="fixed bottom-1/2 right-1/2 flex h-20 translate-x-1/2 translate-y-1/2 items-center justify-center">
        <div className="p-4 text-3xl font-medium text-primary-900">404</div>
        <div className="h-full w-0.5 bg-primary-900" />
        <div className="p-4 text-xl text-primary-900">
          {t("common:page-not-found")}
        </div>
      </div>
    </div>
  );
}

export default TranslatedError;
